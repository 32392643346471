import { ChangeEvent, useState } from "react";
import md5 from "md5";
import useCollapse from "react-collapsed";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import questions from "./questions.json"; // assert { type: "json" };

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/wp-admin" element={<WP />}></Route>
        <Route path="/" element={<Main />}></Route>
      </Routes>
    </Router>
  );
}

export default App;

interface CollabsibleProps {
  header: String;
  content?: String;
  contentType: "RULES" | "TASK_1" | "TEXT" | "IMAGE_HINT";
}

function WP() {
  return (
    <div>
      <h2 style={{ color: "white" }}>H1P5</h2>
    </div>
  );
}

function Main() {
  const [isFirstMissionCompleted, setIsFirstMissionCompleted] = useLocalStorage(
    "firstMissionCompleted",
    false
  );
  const toggleIsFirstMissionCompleted = () => {
    setIsFirstMissionCompleted((current: Boolean) => !current);
  };

  const [x, setX] = useState(0);

  const [state, setState] = useLocalStorage("state", {});
  if (!state.questions || state.questions.length === 0) {
    setState({
      questions: questions,
      answers: state.answers || {},
      answersPlain: state.answersPlain || {},
    });
  }

  function handleAnswerChange(event: ChangeEvent) {
    const qId = event.target.attributes.getNamedItem("name")?.value;
    //@ts-ignore
    const inputValue = event.target.value;
    console.log("answer", qId, inputValue);

    if (qId) {
      state.answers[qId] = md5(inputValue);
      state.answersPlain[qId] = inputValue;
      setState(state);
      setX(x + 1);
    }
  }

  return (
    <div className="App">
      <h1>TreasureHunt</h1>
      <Collapsible
        header="Intro"
        content="You are a team of treasure hunters and you've been hired! A
            malicious actor has stolen three coins of substantial value. Your
            source told you the approximate location - Tenerife. He was also
            able to steal some garbled documents that might be helpful in
            finding all stolen coins. Your client is impatient though. You only
            have 90 minutes left to find all coins or you will have failed your
            assignment and won't receive your finder's fee. But that's not all:
            You've been informed that there are other parties involved as well.
            Seems like your client doesn't really trust you …"
        contentType="TEXT"
      />
      <Collapsible header="Rules &amp; Hints" contentType="RULES" />
      <Collapsible
        header="First Mission (Wayfinding)"
        content=""
        contentType="TASK_1"
      />
      <br />
      <button onClick={toggleIsFirstMissionCompleted}>
        I {isFirstMissionCompleted ? "don't" : ""} know the route!
      </button>
      {isFirstMissionCompleted ? (
        <div>
          <h2>Let's go then!</h2>
          {(state && state.questions ? Object.keys(state.questions) : [])
            .sort()
            .map((qId: string, i) => {
              const qs = state.questions as Record<string, any>;
              const q = qs[qId];

              if (q.displayCondition && q.displayCondition.length > 0) {
                console.log("display conditions", q.displayCondition);
                console.log("display conditions", state.answers);
                let isOk = true;
                for (const condition of q.displayCondition) {
                  if (state.answers) {
                    isOk =
                      isOk &&
                      qs[condition].answers.includes(state.answers[condition]);
                  } else {
                    isOk = false; // not yst answered
                  }
                }
                if (!isOk) {
                  return "";
                }
              }
              return (
                <div key={qId}>
                  <span style={{ fontStyle: "italic", color: "lightgray" }}>
                    {q.type === "ON_THE_GO"
                      ? "🥾"
                      : q.type === "IN_BETWEEN"
                      ? "🏠"
                      : ""}
                    &nbsp;{q.title}
                  </span>
                  <br />
                  <span>{q.question}</span>
                  <br></br>
                  <input
                    name={qId}
                    onChange={handleAnswerChange}
                    value={
                      state.answersPlain && state.answersPlain[qId]
                        ? state.answersPlain[qId]
                        : ""
                    }
                    style={
                      q.answers.includes(state.answers[qId])
                        ? { outline: "4px solid green" }
                        : {}
                    }
                  ></input>
                  <hr></hr>
                  <div style={{ visibility: "hidden" }}>{x}</div>
                </div>
              );
            })}
        </div>
      ) : (
        ""
      )}
      <br />
      <br />
      <br />
      <Collapsible
        header="Hint for first Waypoint (only open if you think you are at the right location and can't find anything!)"
        contentType="IMAGE_HINT"
      />
    </div>
  );
}

const Collapsible = ({ header, content, contentType }: CollabsibleProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="collapsible">
      <div
        className="header"
        {...getToggleProps()}
        style={{ textAlign: "left" }}
      >
        {isExpanded ? "⬆️" : "⬇️"} {header}
      </div>
      <div {...getCollapseProps()}>
        <div>
          {contentType === "TEXT" ? <p className="content">{content}</p> : ""}
          {contentType === "RULES" ? (
            <ul className="content">
              <li>There will be three different types of tasks:</li>
              <ul>
                <li>
                  Find the route! (You should do that before heading out.)
                </li>
                <li>
                  Additional riddles/tasks to be solved whenever you have time.
                  <br></br>
                  🥾 - you will - hopefully find the answer / be inspired on the
                  way<br></br> 🏠 - you can do these at any point in time
                </li>
                <li>Scavenger hunt: Find the actual coins.</li>
              </ul>
              <li>
                If you are an experienced treasureHunter yourself and the tasks
                are too obvious or easy for you, give your teammates some time
                to let them try and find the answer as well. Time is not a
                restricting factor in terms of completing your assignment!
              </li>
              <li>
                If you know the answer because the question is (in part) about
                you or your daily business, sit that one out at first and give
                your teammates some time to solve it themselves.
              </li>
              <li>
                Use whatever resources you like (Bergfex maps, Google,
                Messenger, Compass, ...)
              </li>
              <li>
                Don't try to break the (hastily created) app/website - or should
                you?
              </li>
              <li>
                Be mindful about your surroundings (no private roads/properties
                need to be accessed for the tasks)
              </li>
              <li>
                You will all take the same route with a bit of a delay. So if
                you're closing in on another group just chill / do some
                location-independent tasks.
              </li>
            </ul>
          ) : (
            ""
          )}
          {contentType === "TASK_1" ? (
            <div>
              <p>
                3 Waypoints can be extracted from the following information. At
                each waypoint you should be able to find a hidden coin. To reach
                the first waypoint follow white markers. Also always look
                closely and check your surroundings!
              </p>
              <ol className="content">
                <li>Four clues should lead you to the first location:</li>
                <ul>
                  <li>r r a a r o a o c r n a c b n b</li>
                  <li>!Sunny side in the morning</li>
                  <li>
                    Where red and blue meet and the water line is covered.
                  </li>
                  <li>The path always hides around curvy turns.</li>
                </ul>
                <li>
                  Looking far into the the distance you can spot two numbers …
                  28,X4292 and 16,Y4238
                  <br />
                  X is what you say if you are about to win a famous card game
                  <br />Y hills of rome
                </li>
                <li>Deep down and warm, close to your bed.</li>
              </ol>
            </div>
          ) : (
            ""
          )}
          {contentType === "IMAGE_HINT" ? (
            <div>
              <img src="/IMG_4106.png" style={{ width: "100%" }} alt="img" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

function useLocalStorage(key: any, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
